import { FC, SVGProps } from "react"
import { ReactComponent as ArabicFlag } from "src/shared/icons/arabicFlag.svg"
import { ReactComponent as ArrowTailLeft } from "src/shared/icons/arrow-tail-left.svg"
import { ReactComponent as ArrowLeft } from "src/shared/icons/arrowLeft.svg"
import { ReactComponent as ChangeQuestion } from "src/shared/icons/changeQuestion.svg"
import { ReactComponent as CheckCircle } from "src/shared/icons/check-circle.svg"
import { ReactComponent as Checked } from "src/shared/icons/checked.svg"
import { ReactComponent as CheckmarkCircle } from "src/shared/icons/checkmark-circle.svg"
import { ReactComponent as ChevronLeft } from "src/shared/icons/chevron-left.svg"
import { ReactComponent as Clock } from "src/shared/icons/clock.svg"
import { ReactComponent as CloseIcon } from "src/shared/icons/closeIcon.svg"
import { ReactComponent as CloseModalIcon } from "src/shared/icons/closeModalIcon.svg"
import { ReactComponent as CollapseIcon } from "src/shared/icons/collapseIcon.svg"
import { ReactComponent as CorrectAnswer } from "src/shared/icons/correctAnswer.svg"
import { ReactComponent as CupIcon } from "src/shared/icons/cup.svg"
import { ReactComponent as DocumentIcon } from "src/shared/icons/document.svg"
import { ReactComponent as Edit } from "src/shared/icons/edit.svg"
import { ReactComponent as Email } from "src/shared/icons/email.svg"
import { ReactComponent as EnglishFlag } from "src/shared/icons/englishFlag.svg"
import { ReactComponent as ExtendDotsIcon } from "src/shared/icons/extend-menu-dots.svg"
import { ReactComponent as EyeClosed } from "src/shared/icons/eye-closed.svg"
import { ReactComponent as EyeOpened } from "src/shared/icons/eye-opened.svg"
import { ReactComponent as Facebook } from "src/shared/icons/facebook.svg"
import { ReactComponent as FacebookRefIcon } from "src/shared/icons/facebookRefIcon.svg"
import { ReactComponent as FalseIcon } from "src/shared/icons/falseIcon.svg"
import { ReactComponent as FalseRedIcon } from "src/shared/icons/falseRedIcon.svg"
import { ReactComponent as Google } from "src/shared/icons/google.svg"
import { ReactComponent as AvatarIcon } from "src/shared/icons/headerIcons/avatar.svg"
import { ReactComponent as InfoHeaderIcon } from "src/shared/icons/headerIcons/info.svg"
import { ReactComponent as QuestionIcon } from "src/shared/icons/headerIcons/question.svg"
import { ReactComponent as Hint } from "src/shared/icons/hint.svg"
import { ReactComponent as InfoIcon } from "src/shared/icons/info-circle.svg"
import { ReactComponent as Instagram } from "src/shared/icons/instagram.svg"
import { ReactComponent as Landscape } from "src/shared/icons/landscape.svg"
import { ReactComponent as LensIcon } from "src/shared/icons/lens.svg"
import { ReactComponent as Linkedin } from "src/shared/icons/linkedin.svg"
import { ReactComponent as NoImageIcon } from "src/shared/icons/noImage.svg"
import { ReactComponent as Password } from "src/shared/icons/password.svg"
import { ReactComponent as Plus } from "src/shared/icons/plus.svg"
import { ReactComponent as ProfileEdit } from "src/shared/icons/profileEdit.svg"
import { ReactComponent as PuzzleIcon } from "src/shared/icons/puzzle-piece.svg"
import { ReactComponent as Question } from "src/shared/icons/question.svg"
import { ReactComponent as Scissors } from "src/shared/icons/scissors.svg"
import { ReactComponent as SelectArrow } from "src/shared/icons/selectArrow.svg"
import { ReactComponent as SignOut } from "src/shared/icons/sign-out.svg"
import { ReactComponent as SortIcon } from "src/shared/icons/sorting-arrows.svg"
import { ReactComponent as StepInProgress } from "src/shared/icons/step-in-progress.svg"
import { ReactComponent as Trashcan } from "src/shared/icons/trashcan.svg"
import { ReactComponent as TrophyIcon } from "src/shared/icons/trophy.svg"
import { ReactComponent as TrueGreenIcon } from "src/shared/icons/trueGreenIcon.svg"
import { ReactComponent as TrueIcon } from "src/shared/icons/trueIcon.svg"
import { ReactComponent as UList } from "src/shared/icons/unorderedList.svg"
import { ReactComponent as UploadIcon } from "src/shared/icons/upload.svg"
import { ReactComponent as UserFocusedIcon } from "src/shared/icons/user-focus.svg"
import { ReactComponent as UsersIcon } from "src/shared/icons/users.svg"
import { ReactComponent as WrongAnswer } from "src/shared/icons/wrongAnswer.svg"
import { ReactComponent as Xicon } from "src/shared/icons/xIcon.svg"

export const icons = {
  "arabicFlag": ArabicFlag,
  "arrowLeft": ArrowLeft,
  "arrowTailLeft": ArrowTailLeft,
  "avatarIcon": AvatarIcon,
  "checked": Checked,
  "checkCircle": CheckCircle,
  "checkmarkCircle": CheckmarkCircle,
  "changeQuestion": ChangeQuestion,
  "chevron-left": ChevronLeft,
  "clock": Clock,
  "closeIcon": CloseIcon,
  "closeModalIcon": CloseModalIcon,
  "collapseIcon": CollapseIcon,
  "correctAnswer": CorrectAnswer,
  "cup": CupIcon,
  "document": DocumentIcon,
  "edit": Edit,
  "email": Email,
  "englishFlag": EnglishFlag,
  "extendMenuDots": ExtendDotsIcon,
  "eyeClosed": EyeClosed,
  "eyeOpened": EyeOpened,
  "facebook": Facebook,
  "facebookRefIcon": FacebookRefIcon,
  "falseIcon": FalseIcon,
  "falseRedIcon": FalseRedIcon,
  "google": Google,
  "hint": Hint,
  "info-circle": InfoIcon,
  "infoHeaderIcon": InfoHeaderIcon,
  "instagram": Instagram,
  "landsape": Landscape,
  "lens": LensIcon,
  "linkedin": Linkedin,
  "noImage": NoImageIcon,
  "password": Password,
  "plus": Plus,
  "profileEdit": ProfileEdit,
  "puzzle-piece": PuzzleIcon,
  "question": Question,
  "questionIcon": QuestionIcon,
  "scissors": Scissors,
  "selectArrow": SelectArrow,
  "signOut": SignOut,
  "sortIcon": SortIcon,
  "step-in-progress": StepInProgress,
  "trashcan": Trashcan,
  "trophy": TrophyIcon,
  "trueIcon": TrueIcon,
  "trueGreenIcon": TrueGreenIcon,
  "unorderedList": UList,
  "upload": UploadIcon,
  "userFocused": UserFocusedIcon,
  "users": UsersIcon,
  "wrongAnswer": WrongAnswer,
  "xIcon": Xicon,
} as const

export type IconsType = keyof typeof icons
type SvgIconProps = SVGProps<SVGSVGElement> & { type: IconsType }

export const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type as IconsType] ?? null
  return Icon && <Icon {...svgProps} />
}
