import { Col, Row } from "antd"
import { FC, useEffect, useState } from "react"
import './index.less'
import { NavButton, NavigationProps } from "./elements/NavButton"
import { UserButton } from "./elements/UserButton"
import { AdminLogo } from "src/components/logo/admin-logo"
import { AdminRoutes, SuperAdminRoutes } from "src/pages/routes"
import { useMeQuery } from "src/graphql"

const Side: FC = () => {
  const { data, loading } = useMeQuery({
    fetchPolicy: 'network-only'
  })
  const [navigation, setNavigation] = useState<NavigationProps[]>([])

  const adminNavigation: NavigationProps[] = [
    { id: '1', icon: 'puzzle-piece', path: AdminRoutes.MyGame, hint: 'Game' },
    { id: '2', icon: 'users', path: AdminRoutes.Participants, hint: 'Participants' },
    { id: '3', icon: 'info-circle', path: AdminRoutes.CompanyDetails, hint: 'Company Details Setup' },
  ]

  const superAdminNavigation: NavigationProps[] = [
    { id: '1', icon: 'users', path: SuperAdminRoutes.Users, hint: 'Users' },
  ]

  useEffect(() => {
    if (data?.me?.role?.data?.attributes?.name === 'Admin') {
      setNavigation(adminNavigation)
    }

    if (data?.me?.role?.data?.attributes?.name === 'Super Admin') {
      setNavigation(superAdminNavigation)
    }

  }, [data?.me?.role?.data?.attributes?.name])

  return (
    <Row justify={'space-between'} className={'layout-sidebar'}>
      <Col span={24} className={'layout-sidebar-logo'}>
        <AdminLogo />
      </Col>

      <Col span={24} className={'layout-sidebar-nav'}>
        <div className={'sidebar-nav'}>
          {navigation.map(item => <NavButton key={item.id} {...item} />)}
        </div>
      </Col>

      <Col span={24} className={'layout-sidebar-user'}>
        <UserButton />
      </Col>
    </Row>
  )
}

export { Side }