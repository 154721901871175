import React, { ComponentType, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Loader } from 'src/components/layout';
import { useMeQuery } from 'src/graphql';
import { AdminRoutes } from 'src/pages/routes';

interface WithLoggingOptions {
  requiredRole: string[];
}

function withLogging<T extends object>(
  WrappedComponent: ComponentType<T>,
  options: WithLoggingOptions
) {
  const WithLogging: React.FC<T> = (props) => {
    const { data, loading } = useMeQuery();
    const navigate = useNavigate();

    useEffect(() => {
      if (!loading) {
        const userRole = data?.me?.role?.data?.attributes?.name;

        if (!userRole) {
          navigate(AdminRoutes.SignIn, { replace: true });
        }

        if (!data?.me || !options.requiredRole.includes(userRole as string)) {
          navigate(AdminRoutes.SignIn, { replace: true });
        }
      }
    }, [data, loading, navigate]);

    if (loading) {
      return <Loader spinning size={'large'} />;
    }

    return <WrappedComponent {...props} />;
  };

  WithLogging.displayName = `WithLogging(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return WithLogging;
}

export default withLogging;
