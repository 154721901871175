import { FC } from "react"
import { useNavigate } from "react-router-dom"
import './index.less'
import { AdminRoutes } from "src/pages/routes"
import { Button, Dropdown, MenuProps } from "antd"
import { useAuth } from "src/contexts/AuthProvider"

export const UserButton: FC = () => {
  const { user, logout } = useAuth()

  const navigate = useNavigate()

  const getLetters = (name: string | undefined) => {
    if (!name) return

    const letters = name.split(' ')
      .map(word => word.slice(0, 1))
      .join('')
      .toUpperCase()
    
    return letters
  }

  const onLogout = () => {
    logout()
    window.location.replace(AdminRoutes.SignIn)
  }

  const dropdownMenu: MenuProps['items'] = [
    { key: 'accountSettings', label: 'Account Settings', onClick: () => navigate(AdminRoutes.AccountSettings) },
    { key: 'logout', label: 'Logout', onClick: onLogout },
  ]

  return (
    <Dropdown 
      rootClassName={'sidebar-user-dropdown'}
      placement={'bottomRight'}
      menu={{ items: dropdownMenu }}
    >
      <Button className={'sidebar-user-button'}>
        <span className={'tab-hint'}>
          {getLetters(user?.username)}
        </span>
      </Button>
    </Dropdown>
  )
}