import { Layout as BaseLayout, ConfigProvider } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import theme from "src/themes"
import { useBreakpoints } from "../../hooks/useBreakpoints"
import "./Layout.less"
import { Loader } from "./Loader"

const Layout: FC = () => {
  const { isDesktop } = useBreakpoints()

  return (
    <ConfigProvider theme={theme}>
      <BaseLayout className={"container user"}>
        <BaseLayout style={{ padding: isDesktop ? "40px 156px" : "24px 20px" }}>
          <Suspense
            fallback={
              <div className={"loader-wrapper"}>
                <Loader size={"large"} />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </BaseLayout>
      </BaseLayout>
    </ConfigProvider>
  )
}

export { Layout }
