import { createContext, FC, PropsWithChildren, useContext, useEffect } from "react";
import { useLoginMutation, useMeLazyQuery } from "src/graphql";
import { getAuthToken, removeAuthToken, setAuthToken } from "src/helpers/tokenOperations";

export type LoginFields = 'email' | 'password'

type AuthProviderLogin = (fields: Record<LoginFields, string>) => Promise<void>


type AuthContextProps = {
  user: MeQuery['me'];
  login: AuthProviderLogin;
  logout: () => void;
  getUser: () => void;
}

type AuthContextProviderProps = PropsWithChildren<Partial<AuthContextProps>>;

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export const AuthProvider: FC<AuthContextProviderProps> = ({ children, ...props }) => {
  const [getMe, { data: me, error }] = useMeLazyQuery()

  const [loginMutation] = useLoginMutation();

  const user = error ? null : me?.me;

  useEffect(() => {
    getMe()
  }, [])

  const login = async ({ email, password }: Record<LoginFields, string>) => {
    await loginMutation({ 
      variables: { 
        input: {
          identifier: email,
          password: password
        }
      } 
    }).then((result) => {
      if (result.data?.login.jwt) {
        setAuthToken(result.data?.login.jwt);
        getMe({ context: { headers: { Authorization: `Bearer ${getAuthToken()}` } } })
      }
    })
  }

  const logout = async () => {
    removeAuthToken()
    getMe({ context: { headers: { Authorization: undefined } } })
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        user,
        logout,
        getUser: () => { getMe() }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

