import { Button } from "antd"
import { FC, useState } from "react"
import './index.less'
import { NavLink } from "react-router-dom"
import { SvgIcon, icons } from "src/shared/icons"

export type NavigationProps = {
  id: string
  icon: keyof typeof icons
  hint: string
  path: string
}

export const NavButton: FC<NavigationProps> = ({ icon, path, hint }) => {
  const [showHint, setShowHint] = useState(false)

  const handleMouseEnter = () => {
    setShowHint(true)
  }

  const handleMouseLeave = () => {
    setShowHint(false)
  }

  return (
    <NavLink 
      to={path} 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={({ isActive }) => isActive ? 'sidebar-button active' : 'sidebar-button'}
    >
      <Button className={'sidebar-button'}>
        <SvgIcon type={icon as any} />

        <div className={`button-hint ${showHint ? 'visible' : 'hidden'}`} aria-label={hint}>
          <div className={'button-hint-arrow'}>
            <div className={'button-hint-arrow-line top'} />
            <div className={'button-hint-arrow-line bottom'} />
          </div>

          <span className={'tab-hint'}>{hint}</span>
        </div>
      </Button>
    </NavLink>
  )
}