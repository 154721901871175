export enum CustomerRoutes {
  Home = "/",
  Welcome = "/welcome",
  SignIn = "/sign-in",
  SignUp = "/sign-up",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  CheckEmail = "/verify-email",
  GameStart = "/game-start/:slug",
  GeneralInfo = "/game-start/general-info",
  Faq = "/game-start/faq",
  Quiz = "/game-start/quiz",
  Result = "/result",
  VerifyAccount = "/verify-account",
  Profile = "/profile",
  PersonalInfo = "/profile/personal-information",
}

export enum AdminRoutes {
  SignIn = "/adm/sign-in",
  Admin = "/adm",
  MyGame = "/adm/game",
  Overview = "/adm/game/overview",
  CoverPhoto = "/adm/game/cover-photo",
  Questions = "/adm/game/questions",
  Participants = "/adm/participants",
  CompanyDetails = "/adm/company-details",
  AccountSettings = "/adm/account-settings",
  ForgotPassword = "/adm/forgot-password",
  ResetPassword = "/adm/reset-password",
}

export enum SuperAdminRoutes {
  Users = "/adm/users",
}
