import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  token: {
    colorText: "#FFFFFF",
    fontFamily: "'IBM Plex Sans', sans-serif",
  },
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
    },
    Input: {
      activeBg: "transparent",
    },
    Select: {
      optionSelectedBg: "#FFFFFF33",
    },
    Collapse: {
      colorBorder: "#FFFFFF1A",
    },
    Checkbox: {
      colorBgContainer: "#FFFFFF33",
      colorPrimary: "#FFFFFF33",
      colorPrimaryHover: "#FFFFFF4D",
      controlInteractiveSize: 24,
    },
    List: {
      colorSplit: "#FFFFFF1A",
    },
  },
}

const adminTheme: ThemeConfig = {
  inherit: false,
  token: {
    fontFamily: "'Inter', sans-serif",
  },
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
      colorBgHeader: "unset",
      colorBgBody: "unset",
    },
    Switch: {
      colorPrimaryActive: "#132654",
      colorPrimary: "#132654",
      colorPrimaryHover: "#132654",
    },
    Typography: {
      fontSizeHeading1: 32,
      fontSizeHeading2: 28,
      fontSizeHeading3: 24,
      fontSizeHeading4: 20,
      lineHeightHeading1: 1.25,
      lineHeightHeading2: 1.21,
      lineHeightHeading3: 1.33,
      lineHeightHeading4: 1.2,
    },
  },
}

export { adminTheme, theme as default }
