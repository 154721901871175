import { Layout as BaseLayout, ConfigProvider } from "antd";
import { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Side } from "./Side";
import './AdminLayout.less';
import { adminTheme } from "src/themes";
import withLogging from "src/hoc/withAuth";
import { Loader } from "../Loader";

const AdminLayout: FC = () => {
  return (
    <ConfigProvider theme={adminTheme}>
      <BaseLayout style={{ minHeight: "100vh" }} className={'admin-layout'}>
        <BaseLayout.Sider 
          width={104} 
          collapsedWidth={0}
          className={'admin-layout-sider'}
        >
          <Side />
        </BaseLayout.Sider>
    
        <BaseLayout className={'admin-layout-content'}>
          <Suspense fallback={
            <div className={"loader-wrapper"}>
              <Loader spinning size={"large"} />
            </div>
          }>
            <Outlet />
          </Suspense>
        </BaseLayout>
      </BaseLayout>
    </ConfigProvider>
  );
};

export default withLogging(AdminLayout, { requiredRole: ['Admin', 'Super Admin'] });
