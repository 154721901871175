import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from "@apollo/client"
import { createContext, memo, useContext, type FC, type PropsWithChildren, useEffect } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { LanguageProvider } from "src/contexts/LanguageContext"
import { version } from "../../../package.json"
import introspection, { useMeQuery } from "../../graphql"
import routes from "../../pages"
import "./index.less"
import '../../styles/main.less'
import { getAuthToken, removeAuthToken } from "../../helpers/tokenOperations"
import { AuthProvider } from "src/contexts/AuthProvider"

type ContextProps = {
  app: { version: string }
  user: { authenticated: boolean | null; attributes: UsersPermissionsUser | null }
}

const app: ContextProps["app"] = { version }

const defaultValue: ContextProps = {
  app: app,
  user: { authenticated: null, attributes: null },
}

const Context = createContext({ ...defaultValue })

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  const { data, error, loading } = useMeQuery()

  useEffect(() => {
    let isUnauthorized
    if (error?.networkError instanceof Object && "response" in error.networkError) {
      isUnauthorized = error?.networkError?.response?.status === 401
    }
    if (isUnauthorized && !loading) {
      removeAuthToken()
      location.reload()
    }
  }, [error, loading])

  return (
    <Context.Provider
      value={{
        ...defaultValue,
        ...props,
        user: {
          authenticated: !!data?.me?.uuid,
          attributes: (data?.me as UsersPermissionsUser) ?? null,
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}

const useApp: () => ContextProps = () => useContext(Context)

const httpLink = createHttpLink({
  uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
  credentials: "same-origin",
})

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    }
  }
})

const errorLink = onError(({ networkError }) => {
  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    removeAuthToken()
    window.location.href = '/'
  }
})

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  })
})

const router = createBrowserRouter(routes)

const App: FC = memo(() => (
  <ApolloProvider client={client}>
      <AuthProvider>
        <ContextProvider app={app} user={defaultValue.user}>
            <LanguageProvider>
              <RouterProvider router={router} />
            </LanguageProvider>
        </ContextProvider>
      </AuthProvider>
  </ApolloProvider>
))

export { useApp }

export default App
