import { FC, lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"
import DefaultLayout, { Loader } from "src/components/layout"
import { AdminRoutes, CustomerRoutes, SuperAdminRoutes } from "./routes"
import AdminAuthLayout from "src/components/layout/AdminAuthLayout/AdminAuthLayout"
import AdminLayout from "src/components/layout/AdminLayout/AdminLayout"

const customer = {
  Home: lazy<FC>(() => import("./customer/home")),
  SignIn: lazy<FC>(() => import("./customer/sign-in")),
  Welcome: lazy<FC>(() => import("./customer/welcome")),
  SignUp: lazy<FC>(() => import("./customer/sign-up")),
  CoverPhoto: lazy<FC>(() => import("./admin/game/cover-photo")),
  MyGame: lazy<FC>(() => import("./admin/game/my-game")),
  Game: lazy<FC>(() => import("./admin/game/index")),
  Overview: lazy<FC>(() => import("./admin/game/overview")),
  Questions: lazy<FC>(() => import("./admin/game/questions")),
  ForgotPassword: lazy<FC>(() => import("./customer/forgot-password")),
  ResetPassword: lazy<FC>(() => import("./customer/reset-password")),
  GameStart: lazy<FC>(() => import("./customer/game-start")),
  GeneralInfo: lazy<FC>(() => import("./customer/general-info")),
  Faq: lazy<FC>(() => import("./customer/faq")),
  Quiz: lazy<FC>(() => import("./customer/quiz")),
  Result: lazy<FC>(() => import("./customer/result")),
  CheckEmail: lazy<FC>(() => import("./customer/verify-email")),
  VerifyAccount: lazy<FC>(() => import("./customer/verify-account")),
  Profile: lazy<FC>(() => import("./customer/profile")),
  PersonalInfo: lazy<FC>(() => import("./customer/personal-information")),
}

const admin = {
  Home: lazy<FC>(() => import("./admin/game")),
  Participants: lazy<FC>(() => import("./admin/participants")),
  Game: lazy<FC>(() => import("./admin/game")),
  Overview: lazy<FC>(() => import("./admin/game/overview")),
  CoverPhoto: lazy<FC>(() => import("./admin/game/cover-photo")),
  MyGame: lazy<FC>(() => import("./admin/game/my-game")),
  Questions: lazy<FC>(() => import("./admin/game/questions")),
  CompanyDetails: lazy<FC>(() => import("./admin/company-details")),
  AccountSettings: lazy<FC>(() => import("./admin/account-settings")),
  SignIn: lazy<FC>(() => import("./admin/sign-in")),
  ForgotPassword: lazy<FC>(() => import("./admin/forgot-password")),
  ResetPassword: lazy<FC>(() => import("./admin/reset-password")),
}

const superadmin = {
  Users: lazy<FC>(() => import("./superadmin/users")),
  SignIn: lazy<FC>(() => import("./admin/sign-in")),
}

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: customer.Home,
        path: CustomerRoutes.Home,
      },
      {
        Component: customer.Welcome,
        path: CustomerRoutes.Welcome,
      },
      {
        Component: customer.SignIn,
        path: CustomerRoutes.SignIn,
      },
      {
        Component: customer.SignUp,
        path: CustomerRoutes.SignUp,
      },
      {
        Component: customer.ForgotPassword,
        path: CustomerRoutes.ForgotPassword,
      },
      {
        Component: customer.ResetPassword,
        path: CustomerRoutes.ResetPassword,
      },
      {
        Component: customer.CheckEmail,
        path: CustomerRoutes.CheckEmail,
      },
      {
        Component: customer.GameStart,
        path: CustomerRoutes.GameStart,
      },
      {
        Component: customer.GeneralInfo,
        path: CustomerRoutes.GeneralInfo,
      },
      {
        Component: customer.Faq,
        path: CustomerRoutes.Faq,
      },
      {
        Component: customer.Quiz,
        path: CustomerRoutes.Quiz,
      },
      {
        Component: customer.Result,
        path: CustomerRoutes.Result,
      },
      {
        Component: customer.VerifyAccount,
        path: CustomerRoutes.VerifyAccount,
      },
      {
        Component: customer.Profile,
        path: CustomerRoutes.Profile,
      },
      {
        Component: customer.PersonalInfo,
        path: CustomerRoutes.PersonalInfo,
      },
      {
        Component: Outlet,
        path: "*"
      },
    ],
  },
  {
    Component: AdminLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: superadmin.Users,
        path: SuperAdminRoutes.Users,
      },
    ]
  },
  {
    Component: AdminAuthLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: admin.SignIn,
        path: AdminRoutes.SignIn,
      },
      {
        Component: admin.ForgotPassword,
        path: AdminRoutes.ForgotPassword,
      },
      {
        Component: admin.ResetPassword,
        path: AdminRoutes.ResetPassword,
      }
    ]
  },
  {
    Component: AdminLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: admin.Game,
        path: AdminRoutes.MyGame,
        children: [
          {
            Component: admin.MyGame,
            path: AdminRoutes.MyGame,
          },
          {
            Component: admin.Overview,
            path: AdminRoutes.Overview,
          },
          {
            Component: admin.CoverPhoto,
            path: AdminRoutes.CoverPhoto,
          },
          {
            Component: admin.Questions,
            path: AdminRoutes.Questions,
          },
          {
            Component: Outlet,
            path: "*",
          },
        ],
      },
      {
        Component: admin.Participants,
        path: AdminRoutes.Participants,
      },
      {
        Component: admin.CompanyDetails,
        path: AdminRoutes.CompanyDetails,
      },
      {
        Component: admin.AccountSettings,
        path: AdminRoutes.AccountSettings,
      },
      {
        Component: Outlet,
        path: "*",
      },
    ],
  },
]

export default routes
