import { Layout as BaseLayout, Col, ConfigProvider, Image, Row } from "antd";
import { FC, Suspense } from "react";
import { Outlet } from "react-router";
import { adminTheme } from "src/themes";
import { Loader } from "../Loader";
import imageBg from '/src/shared/images/admin-signin-bg.png'

const AdminAuthLayout: FC = () => {
  return (
    <ConfigProvider theme={adminTheme}>
      <BaseLayout className={'admin-layout'}>
        <Suspense fallback={
          <div className={'loader-wrapper'}>
            <Loader spinning size={'large'} />
          </div>
        }>
          <Row>
            <Col span={12}>
              <Image 
                wrapperStyle={{ height: '100vh', width: '100%' }} 
                style={{ objectFit: 'cover', height: '100%' }} 
                preview={false} 
                src={imageBg} 
                alt={'background'}             
              />
            </Col>

            <Col span={12}>
              <Outlet />
            </Col>
          </Row>
        </Suspense>
      </BaseLayout>
    </ConfigProvider>
  )
}

export default AdminAuthLayout